@import '../styles/styles.scss';

.App {
  text-align: center;
  // background-color: #ECECEC !important;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer-logo {
  // position: absolute;
  left: 0;
  bottom: 0;
}
.wallet-modal {
  .modal-content {
    border-radius: 15px;
  }
}
.card-items-row{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 20px;
  padding: 5px;
  .card-items-row-title{
  font-size: 20px;

  }
}
.card-item-row-selected{
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;  border: 1px solid rgba(0, 0, 0, 0.35) ;
  border-radius: 20px;
  padding: 5px;
  .card-items-row-title{
  font-size: 20px;
  font-weight: bold;
}
}

