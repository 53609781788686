body::-webkit-scrollbar {
  //width: 3px;
  display: none;
}

/* body::-webkit-scrollbar-track {
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
} */

.modal-body::-webkit-scrollbar {
  //width: 3px;
  display: none;
}

/* .modal-body::-webkit-scrollbar-track {
  background-color: transparent;
}

.modal-body::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
} */

.btn {
  width: 115px;
  text-transform: uppercase;
}

.btn-secondary,
.btn-success {
  color: $white;

  &:focus,
  &:active,
  &:hover,
  &:disabled {
    color: $white;
    box-shadow: none;
  }
}

.btn-circle {
  border-radius: 50%;
}

.btn-icon-only {
  width: 40px;
  min-height: 40px;
  padding: 0 0.25rem;
}

.input-bordered {
  border: 1px solid map-get($theme-colors, 'dark');
}

.form-error {
  position: absolute;
  display: none;
  top: 50px;
  left: 40px;
  padding: 0.25rem;
  margin-bottom: 0.5rem;
  z-index: 100;
  color: $white;
  background-color: map-get($theme-colors, 'danger');
  transition: 0.2s;

  &::after {
    content: '';
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: -7px;
    left: 20px;
    z-index: 1;
    background: map-get($theme-colors, 'danger');
    transform: rotate(45deg);
  }

  &.active {
    display: block;
  }
}
