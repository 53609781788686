.bgColor-gray {
  background-color: #ececec !important;
  min-height: 100vh;
}
.calculator-wrapper {
  max-width: 260px;
  margin: 0 auto;
}
.dailer {
  border: none;
  background-color: #ffff;
  border-radius: 10px;
  border: none;
}

.d-text {
  border: none;
  background-color: #b2b1b1;
  color: #ffff !important;
  font-size: 18px;
  width: 100%;
  height: 50px;
}
input::placeholder {
  color: #fff;
}
.digit-btn {
  height: 60px;
  width: 70px;
  font-size: 28px;
  font-weight: 400;
  color: #529ddb;
  border-radius: 10px;
  border: none;
}
.action-wrapper {
  width: 48%;
}
.action-btn {
  height: 60px;
  width: 98%;
  border-radius: 10px;
  border: none;
  background-color: #b2b1b1;
}
