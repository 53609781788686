@keyframes line-from-top {
  0% {
    top: -120%;
  }

  100% {
    top: 0;
  }
}

@keyframes v-logo-lazy {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes images-from-bottom {
  0% {
    bottom: -100%;
  }

  100% {
    bottom: 0%;
  }
}

@keyframes img-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
