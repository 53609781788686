.registration-wrapper {
  .bg-card {
    background-color: #d9d9d9;
    height: fit-content;
  }
  .img-card {
    height: 120px;
    object-fit: cover;
  }

  .pointer-hand {
    cursor: pointer;
  }

  .rounded {
    border-radius: 15px !important;
  }

  .img-container-1 {
    height: auto;
    min-height: 259px;
  }
  .img-card-title {
    height: 28px;
    width: 35px;
  }
  .card-list {
    height: 313px;

    .card-head {
      height: 56px;
      gap: 15px;
      text-align: left;
    }
    .bg-card {
      background-color: #d9d9d9;
    }
    .black-line {
      background-color: black;
      height: 1px;
      width: 90%;
    }
    .pointer-hand {
      cursor: pointer;
    }

    .rounded {
      border-radius: 15px !important;
    }

    .card-content {
      max-height: calc(100% - 56px);
      overflow-y: scroll;
      scroll-behavior: smooth;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #1e323a;
      }
    }
  }
}

.item-overlay {
  position: absolute; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}