.dashboard-div {
  height: 90vh;
}
.dashboard-wrapper {
  height: 100%;
  max-width: 800px;
  margin: 0 auto !important;
}
.logout-btn img {
  height: 25px;
}
.bg-nav {
  background-color: #1e323a;
}
.nav-item {
  font-size: 22px;
}
.btn-menu {
  height: 100px;
  width: 100px;
  border-radius: 5px;
}
.btn-menu img {
  height: 70px;
}
.list-img {
  height: 40px !important;
}

.bg-blue {
  background-color: #66bcbe;
}

.bg-orange {
  background-color: #f49745;
}
.bg-red {
  background-color: #e24349;
}
.bg-purple {
  background-color: #bd609b;
}
