.exit-icon {
  top: 25px;
  // width: 50px;
  // height: 50px;
}
.exit-btn {
  padding: 0 !important;
  text-align: left !important;
  width: 50px !important;
  height: 50px !important;
  background-color: #f39645 !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 40px !important;
  z-index: 1000 !important;
}
.footer-logo {
  max-width: fit-content !important;
}
