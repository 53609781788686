.stations-btn {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.euror-btn {
  height: 50px;
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.action-blue-btn {
  height: 50px;
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 5px;
}
.five-euro-btn {
  height: 50px;
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.close-icon {
  position: absolute;
  top: 2px;
  right: 2px;

  border-radius: 50px;
  font-size: 14px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  color: white;
}
.bg-gray {
  background-color: #6f6f6e;
}
.bg-light-gray {
  background-color: #b0b0b0;
}
.bg-light-blue {
  background-color: #1e9396;
}
.bg-blue {
  background-color: #1e323a;
}

@media (min-width: 576px) {
  .modal-dialog {
    margin: 1.75rem auto;
  }
}
.font-32{
  font-size: 32px;
}
.font-28{
  font-size: 28px;
}