.bill-section {
  background-color: #c6cbce;
  // height: calc(100vh-"250px");
  // height: calc(100% - 100px);
  height: 100vh;
}
.nav-index{
  z-index: -1;
}
ul {
  list-style-type: none;
  font-size: 12px;
}
.item-price {
  font-size: 18px;
}

.item-count {
  font-size: 18px;
}
.blackline {
  height: 2px;
  background-color: black;
}
.card-content {
  // max-height: calc(100% - 56px);
  max-height: 756px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  // scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #5a8995;
  }
}
.pay-common {
  width: 220px;
}
.paySeclected-btn {
  background-color: #9c9b9b;
}
.newSessionMain {
  padding-left: 80px;
}
