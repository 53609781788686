///////////////// colors /////////////////
$red: #e61a3e;
$red-dark: #d82a2a;
$yellow: #fab627;
$yellow-light: #ffe98a;
$blue: #17b5c2;
$alice-blue: #f0f8ff;
$green: #33ab53;
$gray-dark: #2f3e3f;
$gray-light: #f7f3ee;
$white: #ffffff;
$vapiano-warn: #f39644;
$vapiano-prim: #1e323a;
$vapiano-danger: #e24249;
$vapiano-info: #64bbbe;

$colors: (
  'red': $red,
  'red-dark': $red-dark,
  'yellow': $yellow,
  'yellow-light': $yellow-light,
  'blue': $blue,
  'alice-blue': $alice-blue,
  'green': $green,
  'white': $white,
  'gray-dark': $gray-dark,
  'gray-light': $gray-light,
  'vapiano-prim': $vapiano-prim,
  'vapiano-warn': $vapiano-warn,
  'vapiano-danger': $vapiano-danger,
  'vapiano-info': $vapiano-info
);

$primary: $red;
$secondary: $blue;
$success: $green;
$warning: $yellow;
$danger: $red-dark;
$dark: $gray-dark;
$light: $gray-light;
$vapiano-prim: $vapiano-prim;
$vapiano-warn: $vapiano-warn;
$vapiano-danger: $vapiano-danger;
$vapiano-info: $vapiano-info;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  'vapiano-prim': $vapiano-prim,
  'vapiano-warn': $vapiano-warn,
  'vapiano-danger': $vapiano-danger,
  'vapiano-info': $vapiano-info
);

$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');

$font-family-base: 'Barlow Condensed', sans-serif, system-ui, -apple-system, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol', 'Noto Color Emoji';

///////////////// body /////////////////
$body-bg: $white;
$body-color: $gray-dark;

/* $form-text-margin-top:                  0;
$form-text-font-size:                   1rem;
$form-text-color:                       $white; */

$input-padding-y: 0.25rem;
$input-padding-x: 1.25rem;
$input-font-size: 1.084rem;
$input-line-height: 1.5;

$input-padding-y-sm: 0.5rem;
$input-padding-x-sm: 1.25rem;
$input-font-size-sm: 1rem;

$input-padding-y-lg: 0.5rem;
$input-padding-x-lg: 1.25rem;
$input-font-size-lg: 1rem;

$input-border-color: $white;
$input-border-width: 2px;
$input-box-shadow: none;
$input-border-radius: 25px;

$input-focus-border-color: map-get($theme-colors, 'dark');
$input-focus-color: $body-color;
$input-focus-box-shadow: none;

$btn-padding-y: 0.25rem;
$btn-border-radius: 25px;
$btn-font-size: 1.17rem;
$btn-font-weight: 500;

$btn-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
