@import './../../../styles/core';

.main-nav {
  margin-bottom: 64px;
}
.searchbar-nav {
  height: $header-height;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem $header-padding-x;
  font-weight: 500;
  z-index: 1000;
  color: $black;
  background-color: $white;
  text-transform: uppercase;

  &:first-child {
    position: fixed;
    top: 0;
    left: 0;
  }

  &.navbar-expand {
    justify-content: space-between;
  }

  .nav-item {
    .business-name {
      margin-right: 1rem;
    }

    .icon {
      font-size: 1.25rem;
    }
  }
}

.searchbar {
  width: 100%;
  height: $searchbar-colalpsed-height;
  position: absolute;
  left: 0;
  top: $header-height;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  opacity: 1;
  z-index: -1;
  background: $white;
  transition: 0.3s;

  & + main {
    margin-top: $header-height;
    transition: 0.3s;
  }

  &.active {
    opacity: 1;
    z-index: 1;
    height: $searchbar-height;
    padding: 1.25rem;

    & + main {
      margin-top: calc(#{$header-height} + #{$searchbar-height});
    }
  }

  .icon {
    font-size: 1.25rem;
  }
}
