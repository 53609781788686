.card-img {
  width: 110px;
  height: 100px;
}
.success-icon {
  width: 80px !important;
  .success-icon img {
    width: 50px;
    height: 50px;
  }
}
.text-heading {
  font-size: 40px;
  font-weight: 400 !important;
  line-height: 47px;
}
.close-btn {
  width: 150px !important;
  background-color: #f39645 !important;
  color: white !important;
  border-radius: 5px !important;
  font-size: 25px !important;
  font-weight: 400 !important;
}
.gruppe-close-btn {
  background-color: rgba(226, 66, 73, 255) !important;
  color: white !important;
  border-radius: 5px !important;
  font-size: 25px !important;
  width: 150px !important;
  font-weight: 400 !important;
}
