.errorModal-content{
    h5{
    font-size: 22px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    letter-spacing: -0.06em;
    font-weight: 500;
    }
  
}
.errorModal-btn{
    button{
        width: 185px;
        border-radius: 15px;
        height: 45px;
        color: #fff;
        font-size: 20px;
        img{
            height: 20px;
            width: 25px;
        }
    }
}