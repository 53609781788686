.item-wrapper {
  .bg-card {
    background-color: #d9d9d9;
  }

  .pointer-hand {
    cursor: pointer;
  }

  .rounded {
    border-radius: 15px !important;
  }

  .card-list {
    width: 100%;
    height: auto;

    .removedStandardIngredients {
      background-color: #a4a3a4;
    }

    .card-head {
      height: 45px;
      align-items: baseline;
      padding: 8px;
      background-color: #6295a3;
      border-radius: 15px 15px 0 0;
    }

    .card-content {
      // max-height: calc(100% - 56px);
      max-height: 756px;
      overflow-y: scroll;
      scroll-behavior: smooth;
      // scrollbar-width: none;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #5a8995;
      }
    }
  }
}
